ul {
  list-style-type: none;
}
a{
  text-decoration: none;
  color: black;
}
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
  .App-header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, .08);
  }
  .header-first{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 10px 100px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
  }
  .loging {
    display: flex;
    align-items: center; 
    /*margin-right : 100px;*/
    gap : 20px;
  }
  .h-t-name {
    color: #007bff;
  }
  .h-t-logout{
    cursor: pointer;
  }
  .h-t-login {
    /*margin-right : 100px;*/
  }
  .header-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
  }
  .logo-img {
    width : 100px;
    height : auto;
  }
  .h-t-logo-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 1260px;
    height: 50px;
    padding: 0;
  }

  /* pc 기본 검색창 css */
  @media (min-width: 1200px) {
    .h-t-logo-search {
      gap: 40px;
      margin-right: 20px;
    }

    .header-top {
      height: 60px;
    }

    .header-search {
      display: flex;
      align-items: flex-start;
      position: relative;
      width: 100%;
      max-width: 1000px;
      z-index : 20;
    }

    .search-wrap {
      display: flex;
    }

    /* form */
    .location-form {
      border-radius: 25px 0 0 25px;
      background: #fff;
      height: 50px;
    }

    .month-form {
      background: #fff;
      height: 50px;
    }

    .search-form {
      border-radius: 0 25px 25px 0;
      background: #fff;
      width: 500px;
      height: 50px;
    }

    /*filter*/
    .l-search-filter {
      border-radius: 0 0 25px 25px;
      position: absolute;
      top: 20px;
      z-index: 0;
      padding : 40px 10px 10px 10px;
    }

    .m-search-filter {
      border-radius: 0 0 25px 25px;
      padding: 40px 10px 10px 10px;
      position: absolute;
      top: 20px;
      left: 201px;
      z-index: 0;
    }

    .search-result-form {
      position: absolute;
      width: 500px;
      top: 20px;
      left: 503px;
      padding-top : 40px;
    }
  }

  /* pc 기본 검색창(작음) css */
  @media (max-width: 1200px) and (min-width: 1100px) {
    .h-t-logo-search {
      gap: 40px;
      margin-right: 20px;
    }

    .header-top {
      height: 60px;
    }

    .header-search {
      display: flex;
      align-items: flex-start;
      position: relative;
      width: 100%;
      max-width: 1000px;
      z-index : 20;
    }

    .search-wrap {
      display: flex;
    }

    /* form */
    .location-form {
      border-radius: 25px 0 0 25px;
      background: #fff;
      height: 50px;
    }

    .month-form {
      background: #fff;
      height: 50px;
    }

    .search-form {
      border-radius: 0 25px 25px 0;
      background: #fff;
      width: 400px;
      height: 50px;
    }

    /*filter*/
    .l-search-filter {
      border-radius: 0 0 25px 25px;
      position: absolute;
      top: 20px;
      z-index: 0;
      padding : 40px 10px 10px 10px;
    }

    .m-search-filter {
      border-radius: 0 0 25px 25px;
      padding: 40px 10px 10px 10px;
      position: absolute;
      top: 20px;
      left: 201px;
      z-index: 0;
    }

    .search-result-form {
      position: absolute;
      width: 400px;
      top: 20px;
      left: 503px;
      padding-top : 40px;
    }

    .auto-complete-ul{
      width: 400px !important;
    }
    .auto-complete-li{
      width: 370px !important;
      max-width: 370px !important;
    }
    .auto-complete-li > a {
      width: 370px !important;
    }
  }

  /* 모바일 기본 검색창(태블릿) css */
  @media (max-width: 1100px) and (min-width: 700px) {
    .header-logo, .linksty {
      width: 80px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .logo-img {
      width: 80px;
      height: auto;
    }

    .search-wrap, .form{
      height: 35px;
    }

    .header-top {
      height: 100px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .header-search {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      position: relative;
      width: 100%;
      max-width: 1000px;
      z-index : 20;
    }

    .search-wrap {
      display: flex;
    }
    
    /* form */
    .location-form {
      border-radius: 25px 0 0 25px;
      background: #fff;
      height: 35px;
    }

    .month-form {
      border-radius: 0 25px 25px 0;
      background: #fff;
      height: 35px;
    }

    .search-form {
      border-radius: 25px;
      background: #fff;
      width: 500px;
      height: 35px;
    }

    /*filter*/
    .close-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 1.5rem;
    }

    .l-search-filter {
      border-radius: 25px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -215px; /* width(370) + padding(양쪽 합해서 60)의 절반 */
      z-index: 99;
      padding : 50px 30px 30px 30px;
    }

    .m-search-filter {
      border-radius: 25px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -180px; /* width(300) + padding(양쪽 합해서 60)의 절반 */
      z-index: 99;
      padding: 50px 30px 30px 30px;
    }

    .search-result-form {
      position: absolute;
      width: 500px;
      top: 70px;
      left: 50%;
      margin-left: -251px;
      padding-top : 25px;
    }
  }

  /* 모바일 기본 검색창(스마트폰) css  */
  @media (max-width: 700px) and (min-width: 370px) {
    .App-header {
      font-size: 0.8rem;
    }
    .App-header input {
      font-size: 0.7rem;
    }

    .logo-img {
      width : 45px;
      height : auto;
    }

    .h-t-logo-search {
      max-width: 400px;
      height: 25px;
    }

    .header-top {
      height: 70px;
      padding: 6px;
    }

    .header-search {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 5px;
      position: relative;
      width: 100%;
      max-width: 400px;
      z-index : 20;
    }

    .search-wrap {
      display: flex;
    }
    
    /* form */
    .location-form {
      width: 125px !important;
      min-width: 125px !important;
      border-radius: 12px 0 0 12px;
      background: #fff;
      height: 25px;
    }

    .l-img-wrap, .m-img-wrap {
      display: none !important;
    }

    .location-form div {
      margin: 1.5px !important;
    }

    .location-input, .month-input {
      padding-left: 3px !important;
    }

    .l-title, .l-result, .m-title, .m-result {
      line-height: 20px !important;
    }
    .l-title, .m-title {
      min-width: 15px;
    }

    .month-form {
      width: 175px !important;
      min-width: 175px !important;
      border-radius: 0 12px 12px 0;
      background: #fff;
      height: 25px;
    }

    .month-form div {
      margin: 1.5px !important;
    }

    .search-form {
      width: 300px !important;
      min-width: 300px !important;
      border-radius: 12px;
      background: #fff;
      height: 25px;
    }
    .search-form input {
      margin-left: 5px !important;
      border-radius: 12px;
    }

    /*filter*/
    .close-btn {
      position: absolute;
      top: 7px;
      right: 7px;
      font-size: 1.5rem;
    }

    .l-search-filter {
      border-radius: 12px;
      width: 300px !important;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -155px; /* width(300) + padding(양쪽 합해서 10)의 절반 */
      z-index: 99;
      padding : 40px 5px 5px 5px;
    }
    .location-label-wrap {
      width: 290px !important;
      margin: 0 auto;
    }

    .location-label {
      width: 40px !important;
      height: 15px !important;
    }
    .l-reset-btn {
      font-size: 0.7rem;
    }

    .m-search-filter {
      border-radius: 12px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -155px; /* width(300) + padding(양쪽 합해서 10)의 절반 */
      z-index: 99;
      padding: 25px 5px 0 5px;
    }

    .search-result-form {
      position: absolute;
      border-radius: 0 0 12px 12px !important;
      width: 300px !important;
      min-width: 300px !important;
      top: 50px;
      left: 50%;
      margin-left: -151px;
      padding-top : 10px;
    }

    .search-btn {
      margin-right: 7px !important;
    }
    .search-btn svg {
      width: 15px;
      height: 15px;
    }

    .auto-complete-ul{
      width: 300px !important;
    }
    .auto-complete-li{
      width: 290px !important;
      max-width: 290px !important;
    }
    .auto-complete-li > a {
      width: 290px !important;
    }

    /**/
    .datepicker .month-cur {
      font-size: 0.85rem !important;
    }
  }

  /* 모바일 기본 검색창(작은 스마트폰) css  */
  @media (max-width: 370px) and (min-width: 320px) {
    .App-header {
      font-size: 0.8rem;
    }
    .App-header input {
      font-size: 0.7rem;
    }

    .logo-img {
      width : 45px;
      height : auto;
    }

    .h-t-logo-search {
      max-width: 350px;
      height: 25px;
    }

    .header-top {
      height: 60px;
      padding: 6px;
    }

    .header-search {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 5px;
      position: relative;
      width: 100%;
      max-width: 350px;
      z-index : 20;
    }

    .search-wrap {
      display: flex;
    }
    
    /* form */
    .search-form {
      width: 250px !important;
      min-width: 250px !important;
      border-radius: 12px;
      background: #fff;
      height: 25px;
    }
    .search-form input {
      margin-left: 5px !important;
      border-radius: 12px;
    }

    /*filter*/
    .search-result-form {
      position: absolute;
      border-radius: 0 0 12px 12px !important;
      width: 250px !important;
      min-width: 250px !important;
      top: 10px;
      left: 50%;
      margin-left: -126px;
      padding-top : 10px;
    }

    .search-btn {
      margin-right: 7px !important;
    }
    .search-btn svg {
      width: 15px;
      height: 15px;
    }

    .auto-complete-ul{
      width: 250px !important;
    }
    .auto-complete-li{
      width: 230px !important;
      max-width: 230px !important;
    }
    .auto-complete-li > a {
      width: 230px !important;
    }

  }

  /* 모바일 기본 검색창(작은 스마트폰(min: 250, 아래는 무시)) css  */
  @media (max-width: 320px) {
    .App-header {
      font-size: 0.8rem;
    }
    .App-header input {
      font-size: 0.7rem;
    }

    .logo-img {
      width : 45px;
      height : auto;
    }

    .h-t-logo-search {
      max-width: 250px;
      height: 25px;
    }

    .header-top {
      height: 60px;
      padding: 0;
    }

    .header-search {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 5px;
      position: relative;
      width: 100%;
      z-index : 20;
    }

    .search-wrap {
      display: flex;
    }
    
    /* form */
    .search-form {
      width: 200px !important;
      min-width: 200px !important;
      border-radius: 12px;
      background: #fff;
      height: 25px;
    }
    .search-form input {
      margin-left: 5px !important;
      border-radius: 12px;
    }

    /*filter*/
    .search-result-form {
      position: absolute;
      border-radius: 0 0 12px 12px !important;
      width: 200px !important;
      min-width: 200px !important;
      top: 10px;
      left: 50%;
      margin-left: -100.5px;
      padding-top : 20px;
    }

    .search-btn {
      margin-right: 7px !important;
    }
    .search-btn svg {
      width: 15px;
      height: 15px;
    }

    .auto-complete-ul{
      width: 200px !important;
    }
    .auto-complete-li{
      width: 180px !important;
      max-width: 180px !important;
      height: 2rem !important;
      line-height: 2rem !important;
    }
    .auto-complete-li > a {
      width: 180px !important;
      height: 2rem !important;
    }

  }


  /**/
  .location-form {
    width: 200px;
    min-width: 200px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border: 1px solid rgba(0, 0, 0, .15);
    z-index: 1;
    cursor: pointer;
  }
  .location-form .location-input {
    display: flex;
    justify-content: center;
    margin-left: 10px;
    z-index: 1;
  }
  .l-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .location-form img {
    width : 25px;
    height: 25px;
  }
  .location-form div {
    margin: 3px;
  }
  .l-title {
    font-weight: 600;
    color : #0368e5;
    line-height: 30px;
  }
  .l-result {
    font-weight: 600;
    line-height: 30px;
  }
  .month-form {
    width: 300px;
    min-width: 300px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, .15);
    z-index: 1;
    cursor: pointer;
  }
  .month-form .month-input {
    margin-left: 10px;
    display: flex;
    z-index: 1;
  }
  .m-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .month-form img {
    width : 25px;
    height: 25px;
  }
  .month-form div {
    margin: 3px;
  }
  .m-title {
    color : #0368e5;
    font-weight: 600;
    line-height: 30px;
  }
  .m-result {
    font-weight: 600;
    line-height: 30px;
  }
  .search-form {
    max-width: 500px;
    min-width: 400px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, .15);
    z-index: 1;
  }
  
  .search-form input {
    height: 18px;
    flex: 1;
    margin-left: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: justify;
    color: #111;
    border: none;
    outline: none;
    width: 100%;
  }
  
  .search-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    margin: 0 16px 0 0;
    padding: 0;
  }
  
  .l-search-filter {
    width: 370px;
    height: 340px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    flex-wrap: wrap;
  }
  .location-label-wrap {
    margin-top: 5px;
    width: 370px;
    height: 280px;
    display: flex;
    flex-wrap: wrap;
  }
  .location-label {
    display: flex;
    align-items: center;
    justify-content: center; /* 중앙 정렬 */
    height : 20px;
    width: 60px; /* 고정 너비 */
    margin: 5px 5px; /* 위아래 여백 */
    padding: 10px; /* 안쪽 여백 */
    border: 1px solid #ccc; /* 테두리 */
    border-radius: 5px; /* 둥근 모서리 */
    cursor: pointer; /* 커서 포인터로 변경 */
    transition: background-color 0.3s; /* 배경색 변화 애니메이션 */
  }
  .location-label:hover {
    background-color: #f0f0f0; /* 호버시 배경색 변화 */
  }
  /* 선택된 라벨의 스타일 */
  .location-label.active {
    background-color: #007bff; /* 선택된 라벨의 배경색을 파란색으로 설정 */
    color: white; /* 텍스트 색상 변경 */
  }
  .hidden-ckbox {
    display: none; /* 라디오 버튼 숨기기 */
  }
  .l-reset-btn {
    width: 90px;
    height: 40px;
    background-color: #eaeaea;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
  }
  .l-reset-btn:hover {
    background-color: #ffdcdc;
  }
  .l-reset-btn-wrap {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 370px;
    height: 40px;
    display: flex;
    justify-content: right;
  }

  .m-search-filter {
    display: flex;
    justify-content: center;
    width: 300px;
    height: 280px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  .m-search-stend {
    display: flex;
    justify-content: center;
    width : 80%;
    height: 50px;
  }
  .start-m {
    margin-left: 10px;
    width : 100%;
  }
  .end-m {
    margin-left: 80px;
  }
  .m-reset-btn {
    font-size: 600;
    position: absolute;
    bottom: 10px;
    height : 40px;
    width: 70px; /* 고정 너비 */
    margin: 5px 5px; /* 위아래 여백 */
    padding: 10px; /* 안쪽 여백 */
    border: 1px solid #ccc; /* 테두리 */
    border-radius: 5px; /* 둥근 모서리 */
    cursor: pointer; /* 커서 포인터로 변경 */
    transition: background-color 0.3s; /* 배경색 변화 애니메이션 */
  }
  .m-reset-btn:hover {
    color: white;
    background-color: hsl(0, 100%, 50%); /* 호버시 배경색 변화 */
  }
  .m-search-btn {
    font-size: 600;
    position: absolute;
    right: 30px;
    bottom: 10px;
    height : 40px;
    width: 60px; /* 고정 너비 */
    margin: 5px 5px; /* 위아래 여백 */
    padding: 10px; /* 안쪽 여백 */
    border: 1px solid #ccc; /* 테두리 */
    border-radius: 5px; /* 둥근 모서리 */
    cursor: pointer; /* 커서 포인터로 변경 */
    transition: background-color 0.3s; /* 배경색 변화 애니메이션 */
  }
  .m-search-btn:hover {
    color: white;
    background-color: #007bff; /* 호버시 배경색 변화 */
  }


  /**/
  .search-result-form {
    max-width: 500px;
    min-width: 400px;
    background: #fff;
    border-radius: 0 0 25px 25px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    z-index: 0;
    display: flex;
    justify-content: center;
  }
  .s-r-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .auto-complete-ul {
    width: 500px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .auto-complete-li {
    width: 450px;
    max-width: 450px;
    height: 3rem;
    line-height: 3rem;
    border-bottom: 1px solid #eee;
  }
  .auto-complete-li:last-child {
    border-bottom: none;
  }
  .auto-complete-li > a {
    display: inline-block;
    width: 450px;
    height: 3rem;
    color: #000;
    text-align: center;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .auto-complete-li:hover > a {
    color: #0368e5;
  }

  .header-bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    margin-top : 20px;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
  }
  
  .h-b-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    padding: 0;
    gap: 20px;
    width: 100%;
    max-width: 1280px;
  }
  .h-b-menu ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 39px;
    padding: 0;
    gap : 0;
    border-radius: 6px;
    white-space: nowrap;
    list-style-type: none;
    margin: 0;
  }
  .h-b-menu li {
    display: flex;
    align-items: center;
    height: 38px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 19px;
    margin-right : 20px;
    position: relative;
  }
  .h-b-menu li:last-child {
    margin-right: 0;
  }
  .h-b-menu .sep-header li a {
    color: #0368e5;
  }

  .h-b-menu li::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px; 
    height: 2px;
    opacity: 0; 
    transition: opacity 0.3s; 
  }
  .h-b-menu .normal-header li::after {
    background-color: #000;
  }
  .h-b-menu .sep-header li::after {
    background-color: #0368e5;
  }
  .h-b-menu li:hover::after, .h-b-menu ul li.active::after {
    opacity: 1;
  }
  .menu-img {
    display: none;
  }
  .menuDivider {
    display: flex;
    align-items: center;
    width: 1px;
    height: 15px;
    background-color: #ddd;
  }

/*--------768px 미만------------*/
/* @media screen and (max-width: 1000px) {
  .App-header {
    display: flex;
    flex-direction: column;
  }
  .header-top{
    position: fixed;
    width:100%;
    background-color: white;
    z-index: 9999;
  }
  .h-t-logo-search{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left : 16px;
    margin-top : 10px;
  }
  .logo-img {
    width: 80px; 
    height: auto;
  }
  .header-search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100%;
    margin-left : 10px;
  }
  .search-form {
    width: 98%;
    height: 40px;
    background: #fff;
    border-radius: 25px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border: 1px solid rgba(0, 0, 0, .15);
  }
  .search-form input{
    height: 18px;
    flex: 1;
    margin-left: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: justify;
    color: #111;
    border: none;
    outline: none;
    width: 100%;
  }
  .search-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    margin: 0 16px 0 0;
    padding: 0;
  }
  .header-bottom {
    display: flex;
    justify-content: center;
    margin-top : 20px;
    margin-bottom: 20px;
  }

  .h-b-menu ul {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 0;
    gap : 30px;
    white-space: nowrap;
    list-style-type: none;
    cursor: pointer;
  }
  .h-b-menu li {
    display: flex;
    flex-direction: column;
    width : 80px;
    height : 80px;
    align-items: center;
  }
  .h-b-menu .menu-img {
    height: 50px;
  }
  .h-b-menu .menu-img img {
    height: 50px;
    width: auto;
  }
  .h-b-menu .menu-title {
    text-decoration-line: none;
    color : black;
  }
} */