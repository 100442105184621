svg.koreaMap {
    width: 100%;
    height: 100%;
    fill: grey;
}

svg.koreaMap path:hover {
    fill: #9aadc5;
    cursor: pointer;
}

svg.koreaMap text {
    font-size: 1.5rem;
    font-weight: bold;
    fill: black;
    cursor: default;
}

svg.koreaMap path.selected {
    fill: #5693e0;
}