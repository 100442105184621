@import './datePickerFont.css';

/*Calendar 속성*/
.datepicker .disabled {
    color: #ccc;
    cursor: default !important;
}

.datepicker .muted {
    font-size: 0;
    cursor: default !important;
}

.datepicker .picked {
    background: #5693e0;
    color: #fff !important;
}

.datepicker .picked-btw {
    background: #a2ccff;
    color: #fff !important;
}

/*Calendar*/
.Calendar {
    display: block;
    font-weight: normal;
    font-family: 'LINESeedKR-Bd';
    color: #000;

    padding-bottom: 0.5rem;
}

.datepicker, .Calendar {
    width: 18rem;
    margin: 0 auto;
}

.datepicker, .Calendar {
    position: relative;
}

.datepicker ul, .datepicker ol {
    list-style: none;
    padding: 0;
    border: 0;
}

/*month-check*/
.month-check-bar {
    width: 18rem;
    height: 2.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
}

.month-check-bar ul {
    height: 2.5rem;

    margin: 0;
}

.month-check-bar ul > li {
    float: left;
    height: 2.5rem;
    margin: 0.1rem 0.3rem;

    line-height: 2.5rem;
}

.month-check-bar .month-prev, .month-check-bar .month-next {
    width: 2.5rem;
    font-size: 2.0rem;
    cursor: pointer;
}

.month-check-bar .month-cur {
    width: 5.0rem;
    font-size: 0.9rem;
    font-weight: bold;
}

/*week-check*/
.week-check-bar {
    width: 18rem;
    height: 2.0rem;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    margin-bottom: 0.2rem;

    font-size: 0.8rem;
    color: #666;
}

.week-check-bar ul {
    height: 1.6rem;

    background-color: #f4f6f9;
    border-radius: 5rem;
    margin: 0.2rem;
}

.week-check-bar ul > li {
    float: left;
    width: 2rem;
    height: 1.6rem;
    margin: 0.1rem 0.2rem;

    line-height: 1.6rem;

    font-weight: bold;
}

/*day-check*/
.day-check {
    width: 18rem;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    font-size: 0.8rem;
}

.day-check ul {
    margin: 0;
}

.day-check ul > li {
    float: left;
    width: 2rem;
    height: 2rem;
    margin: 0.1rem 0.2rem;

    border-radius: 50%;

    line-height: 2rem;
}

.day-check > ul > ul > li {
    cursor: pointer;
}

    /*일요일*/
.day-check li:nth-child(7n + 1) {
    color: #ff322e;
}

.day-check li:nth-child(7n + 1).disabled {
    color: #f8c4c4;
}
