#region-mainContainer {
    position: relative;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

#region-mainContainer .contents {
    display: flex;
    justify-content: space-between;

    border-radius: 1rem;
    border: 0.08rem solid rgb(230, 228, 228);

    box-shadow: 0.15rem 0.15rem 0.15rem rgba(198, 198, 198, 0.699);

    margin: 0 auto;
}

/* pc */
@media (min-width: 1100px) {
    #region-mainContainer .contents {
        flex-direction: row;
        width: 1100px;
        height: 560px;
    }
    .leftBox {
        margin-top: 40px;
        margin-left: 40px;
        width: 360px;
        height: 480px;
    }
    .leftBoxTop .regionTitle {
        width: 100%;
        padding-left: 0.5rem;
    }
    .rightBox {
        margin-top: 40px;
        margin-right: 40px;
        width: 640px;
        height: 480px;
        justify-content: space-between;
    }
    .rightBoxTop {
        max-width: 40rem;
        height: 26rem;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 1.5rem;
    }

    .rightBoxTop a {
        position: relative;
        max-width: 12rem;
    }
}

/* 태블릿 */
@media (max-width: 1099px) and (min-width: 720px) {
    #region-mainContainer .contents {
        flex-direction: column;
        width: 720px;
    }
    .leftBox {
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 30px;
        width: 360px;
    }
    .leftBoxTop {
        height: 3rem !important;
        justify-content: center;
    }
    .leftBoxTop .regionTitle {
        font-size: 1.5rem !important;
    }
    .leftBoxBottom {
        margin-top: 20px;
    }
    .regionTitle-btn {
        font-size: 15px;
    }
    .leftBoxTop .regionTitle {
        text-align: center;
    }
    .rightBox {
        margin: 0 auto;
        margin-bottom: 40px;
        width: 640px;
        height: 480px;
        justify-content: space-between;
    }
    .rightBoxTop {
        max-width: 40rem;
        height: 26rem;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 1.5rem;
    }
    .rightBoxTop a {
        position: relative;
        max-width: 12rem;
    }
}

/* 스마트폰(min: 320) */
@media (max-width: 719px) and (min-width: 320px) {
    #region-mainContainer .contents {
        flex-direction: column;
        width: 300px;
    }
    .leftBox {
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 30px;
        width: 250px;
    }
    .leftBoxTop {
        height: 1.5rem !important;
        justify-content: center;
    }
    .leftBoxBottom {
        margin-top: 10px;
        height: 351px !important;
    }
    svg.koreaMap {
        width: 250px;
        height: auto;
        margin: 0 auto;
    }
    .regionTitle-btn {
        font-size: 10px;
        color: #0368e5;
    }
    .leftBoxTop .regionTitle {
        text-align: center;
        font-size: 1rem !important;
        color: #0368e5;
    }
    .rightBox {
        margin: 0 auto;
        margin-bottom: 20px;
        width: 250px;
        justify-content: center;
    }
    .rightBoxTop {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        margin-bottom: 20px;
    }
    .rightBoxTop a {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .rightBoxTop .wrapBox {
        flex-direction: row !important;
        max-width: 250px !important;
    }
    .rightBoxTop .posterImg {
        width: 3rem !important;
        height: 4rem !important;
    }
    .rightBoxTop .textWrap {
        width: 200px !important;
    }
    .rightBoxTop .no_result {
        font-size: 1rem !important;
        margin: 0 auto !important;
    }
    .rightBoxBottom {
        justify-content: center !important;
    }
    .regionGoBtn {
        font-size: 0.9rem !important;
    }
}

/* 스마트폰(min: 250, 아래는 무시) */
@media (max-width: 320px) {
    #region-mainContainer .contents {
        flex-direction: column;
        width: 250px;
    }
    .leftBox {
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 30px;
        width: 250px;
    }
    .leftBoxTop {
        height: 1.5rem !important;
        justify-content: center;
    }
    .leftBoxBottom {
        margin-top: 10px;
        height: 351px !important;
    }
    svg.koreaMap {
        width: 250px;
        height: auto;
        margin: 0 auto;
    }
    .regionTitle-btn {
        font-size: 10px;
        color: #0368e5;
    }
    .leftBoxTop .regionTitle {
        text-align: center;
        font-size: 1rem !important;
        color: #0368e5;
    }
    .rightBox {
        margin: 0 auto;
        margin-bottom: 20px;
        width: 250px;
        justify-content: center;
    }
    .rightBoxTop {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        margin-bottom: 20px;
    }
    .rightBoxTop a {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .rightBoxTop .wrapBox {
        flex-direction: row !important;
        max-width: 250px !important;
    }
    .rightBoxTop .posterImg {
        width: 3rem !important;
        height: 4rem !important;
    }
    .rightBoxTop .textWrap {
        width: 200px !important;
    }
    .rightBoxTop .no_result {
        font-size: 1rem !important;
        margin: 0 auto !important;
    }
    .rightBoxBottom {
        justify-content: center !important;
    }
    .regionGoBtn {
        font-size: 0.9rem !important;
    }
}

/**/
.leftBox {
    display: flex;
    flex-direction: column;

    position: relative;
}

.leftBoxTop {
    width: 100%;
    height: 5rem;

    display: flex;
    align-items: center;
}

.leftBoxTop .regionTitle {
    height: 3rem;
    line-height: 3rem;
    font-size: 2rem;
    font-weight: bold;
}

.leftBoxBottom {
    position: relative;
    width: 100%;
    height: 25rem;
}

.leftBoxBottom .mapImg {
    width: 100%;
    height: 100%;
}


/**/
.rightBox {
    position: relative;

    display: flex;
    flex-direction: column;
}

.rightBoxTop {
    width: 100%;
}

.rightBoxTop a {
    border-radius: 0.5rem;
}

.rightBoxTop a:hover {
    background-color: rgb(230, 228, 228);
}

.rightBoxTop .no_result {
    margin-top: 2rem;
    margin-left: 1rem;
    font-size: 1.3rem;
}

.rightBoxTop .wrapBox {
    position: relative;
    max-width: 12rem;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 0.5rem;
    border: 0.08rem solid rgb(230, 228, 228);
}

.rightBoxTop .posterImg {
    position: relative;
    width: 6rem;
    height: 8rem;
    border-radius: 0.5rem;
    border: 0.08rem solid gray;
}

.rightBoxTop .posterImg img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
}

.rightBoxTop .textWrap {
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.rightBoxTop .posterTitle {
    width: calc(100% - 2rem);
    height: 1.8rem;
    line-height: 1.8rem;
    text-align: center;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 1rem;
}

.rightBoxTop .posterDate {
    width: 100%;
    height: 1.2rem;
    line-height: 1.2rem;
    text-align: center;

    font-size: 0.8rem;
}

/**/
.rightBoxBottom {
    width: 100%;
    height: 2.5rem;

    display: flex;
    justify-content: right;
    align-items: baseline;
}

.regionGoBtn {
    border-radius: 0.5rem;
    border: 1px solid black;
    padding: 0.5rem;
    font-size: 1rem;
}



